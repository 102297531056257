<template>
  <div class="mb-3">
    <label
      v-if="title"
      v-bind:for="componentId + '-content'"
      class="custom-label"
      >{{ title }}</label
    >
    <ckeditor
      v-if="!loading"
      v-bind:id="componentId + '-content'"
      v-model="computedData"
      v-bind:config="getEditorConfig"
      v-bind:editor="editor"
    ></ckeditor>
  </div>
</template>
<script>
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor.js";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Autolink from "@ckeditor/ckeditor5-link/src/autolink.js";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder.js";
import CKFinderUploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter.js";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor.js";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily.js";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight.js";
import HtmlEmbed from "@ckeditor/ckeditor5-html-embed/src/htmlembed.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle.js";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount.js";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Mention from "@ckeditor/ckeditor5-mention/src/mention";

import constant from "../../../shared/constant";
export default {
  name: "WeEditor",
  data() {
    return {
      loading: true,
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          SimpleUploadAdapter,
          Alignment,
          Autoformat,
          Autolink,
          BlockQuote,
          Bold,
          CKFinder,
          CKFinderUploadAdapter,
          CodeBlock,
          Essentials,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HtmlEmbed,
          Image,
          ImageCaption,
          ImageInsert,
          ImageStyle,
          ImageUpload,
          ImageResize,
          ImageToolbar,
          Indent,
          Italic,
          Link,
          LinkImage,
          List,
          ListStyle,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          Strikethrough,
          Table,
          TableToolbar,
          TextTransformation,
          Underline,
          WordCount,
          Mention,
        ],
        toolbar: {
          items: [
            "heading",
            "|",
            "alignment",
            "bold",
            "italic",
            "strikethrough",
            "underline",
            "link",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "fontfamily",
            "fontsize",
            "fontColor",
            "fontBackgroundColor",
            "-",
            "codeBlock",
            "insertTable",
            "imageUpload",
            "blockQuote",
            "htmlEmbed",
            "|",
            "undo",
            "redo",
          ],
          shouldNotGroupWhenFull: true,
        },
        simpleUpload: {
          uploadUrl: constant.API_URL + "upload/" + this.name,
          withCredentials: false,
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + this.accessToken,
          },
        },
        image: {
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
            "|",
            "linkImage",
            "|",
            "resizeImage"
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        mention: {
          feeds: [
            {
              marker: this.mentionMarker,
              feed: this.getFeedItems,
              itemRenderer: this.customItemRenderer,
            },
          ],
        },
        htmlEmbed: {
          showPreviews: true,
          sanitizeHtml: (inputHtml) => {
            // Strip unsafe elements and attributes, e.g.:
            // the `<script>` elements and `on*` attributes.
            const outputHtml = inputHtml;

            return {
              html: outputHtml,
              // true or false depending on whether the sanitizer stripped anything.
              // hasChanged: true,
            };
          },
        },
        link: {
          decorators: {
            openInNewTab: {
              mode: "manual",
              label: "Yeni Sekmede Aç",
              defaultValue: false, // This option will be selected by default.
              attributes: {
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
          },
        },
      },
    };
  },
  props: {
    name: {
      default: "",
    },
    content: {
      default: "",
    },
    accessToken: {
      default: "",
    },
    title: {
      default: "",
    },
    mentionFeed: {
      default: () => [],
    },
    mentionMarker: {
      default: "@",
    },
    showToolbar: {
      default: true,
    },
  },
  model: {
    prop: "content",
  },
  methods: {
    getFeedItems(queryText) {
      // As an example of an asynchronous action, return a promise
      // that resolves after a 100ms timeout.
      // This can be a server request or any sort of delayed action.
      return new Promise((resolve) => {
        setTimeout(() => {
          const itemsToDisplay = this.mentionFeed;
          itemsToDisplay
            // Filter out the full list of all items to only those matching the query text.
            .filter(isItemMatching)
            // Return 10 items max - needed for generic queries when the list may contain hundreds of elements.
            .slice(0, 10);

          resolve(itemsToDisplay);
        }, 100);
      });

      // Filtering function - it uses `name` and `username` properties of an item to find a match.
      function isItemMatching(item) {
        // Make the search case-insensitive.
        const searchString = queryText.toLowerCase();

        // Include an item in the search results if name or username includes the current user input.
        return (
          item.name.toLowerCase().includes(searchString) ||
          item.id.toLowerCase().includes(searchString)
        );
      }
    },
    customItemRenderer(item) {
      const itemElement = document.createElement("div");

      itemElement.classList.add("offer-info");
      itemElement.classList.add("d-flex");
      itemElement.classList.add("align-item-center");
      itemElement.classList.add("justify-content-between");
      //itemElement.id = `mention-list-item-id-${ item.id }`;
      itemElement.textContent = `${item.name}`;

      const usernameElement = document.createElement("span");

      usernameElement.classList.add("text-muted");
      usernameElement.textContent = item.id;

      itemElement.appendChild(usernameElement);

      return itemElement;
    },
  },
  computed: {
    componentId() {
      return "weeditor-" + helper.getRandomInteger(1, 999999);
    },
    computedData: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getEditorConfig() {
      if (!this.showToolbar) delete this.editorConfig.toolbar;

      return this.editorConfig;
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 0);
  },
};
</script>
<style lang="scss">
.ck-editor__editable_inline {
  min-height: 250px;
}
</style>
